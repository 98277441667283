/*
    ADMIN(99),
    CUSTOMER_SERVICE(98),
    WAREHOUSE(97),
    FINANCE(96),
    LOGISTIC(95),
    STOCKIST(1),
    DEALER(2),
    SUB_DEALER(2),
    CUSTOMER(4),
    STAFF(5);
 */
const newBadges = ["report"];
const newBadge = {
  variant: "warning",
  text: "NEW"
};

const navs = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    name: "Sales Order",
    url: "/sales-order",
    icon: "icon-basket-loaded",
    roles: [99, 98, 97, 96, 95, 94],
    children: [
      {
        id: "nav-so-stockist",
        name: "Intertec - Stockist",
        url: "/so-intertec-stockist",
        icon: "icon-basket-loaded"
      },
      {
        id: "nav-so-stockist-dealer",
        name: "Stockist - Dealer",
        url: "/so-stockist-dealer",
        icon: "icon-basket-loaded"
      },
      {
        id: "nav-so-stockist-customer",
        name: "Stockist - Customer",
        url: "/so-stockist-customer",
        icon: "icon-basket-loaded"
      },
      {
        id: "nav-so-dealer-customer",
        name: "Dealer - Customer",
        url: "/so-dealer-customer",
        icon: "icon-basket-loaded"
      }
    ]
  },
  {
    id: "nav-point",
    name: "Poin",
    icon: "icon-wallet",
    roles: [99, 94],
    children: [
      {
        id: "nav-point-rule",
        name: "Point Rule",
        url: "/point-rule",
        icon: "icon-wallet"
      },
      {
        id: "nav-point-history",
        name: "Point History",
        url: "/point-balance",
        icon: "icon-wallet"
      },
      {
        id: "nav-point-inject",
        name: "Inject Point",
        url: "/point-inject",
        icon: "icon-wallet"
      },
      {
        id: "nav-point-stockist",
        name: "Point Stockist",
        url: "/stockist-point",
        icon: "icon-wallet"
      },
      {
        id: "nav-point-dealer",
        name: "Point Dealer",
        url: "/dealer-point",
        icon: "icon-wallet"
      }
    ]
  },
  {
    name: "Product",
    icon: "icon-diamond",
    roles: [99, 98],
    children: [
      {
        id: "nav-product-all",
        name: "Product",
        url: "/product",
        icon: "icon-drop"
      },
      {
        id: "nav-product-imei",
        name: "IMEI",
        url: "/imei",
        icon: "icon-drop"
      },
      {
        id: "nav-product-imei-history",
        name: "IMEI History",
        url: "/imei-history",
        icon: "icon-drop"
      },
      {
        id: "nav-product-brand",
        name: "Brand",
        url: "/brand",
        icon: "icon-drop"
      },
      {
        id: "nav-product-category",
        name: "Category",
        url: "/product-category",
        icon: "icon-drop"
      }
    ]
  },
  {
    id: "nav-stockist",
    name: "Stockist",
    url: "/stockist",
    icon: "icon-organization",
    roles: [99, 98]
  },
  {
    id: "nav-dealer",
    name: "Dealer",
    icon: "icon-organization",
    roles: [99, 98],
    children: [
      {
        id: "nav-dealer-all",
        name: "Dealer All",
        url: "/dealer",
        icon: "icon-organization",
        roles: [99, 98]
      },
      {
        id: "nav-dealer-pending",
        name: "Dealer Pending",
        url: "/dealer-pending",
        icon: "icon-organization",
        roles: [99, 98]
      }
    ]
  },
  {
    id: "nav-report",
    name: "Report",
    icon: "icon-grid",
    roles: [99, 98],
    children: [
      {
        id: "nav-report-stock",
        name: "Stock",
        url: "/report-stock",
        icon: "icon-grid",
        roles: [99, 98]
      },
      {
        id: "nav-report-stock-breakdown",
        name: "Stock Breakdown",
        url: "/report-stock-deep",
        icon: "icon-grid",
        roles: [99, 98]
      }
    ]
  },
  {
    id: "nav-reward",
    name: "Reward",
    url: "/reward",
    icon: "icon-present",
    roles: [99, 98]
  },
  {
    id: "nav-redeem",
    name: "Redeem",
    icon: "icon-present",
    roles: [99, 98],
    children: [
      {
        id: "nav-redeem-all",
        name: "Redeem All",
        url: "/redeem",
        icon: "icon-present",
        roles: [99, 98],
      },
      {
        id: "nav-redeem-all",
        name: "Redeem Pending",
        url: "/redeem-pending",
        icon: "icon-present",
        roles: [99, 98],
      }
    ]
  },
  {
    id: "nav-customer",
    name: "Customer",
    url: "/customer",
    icon: "icon-user",
    roles: [99, 98]
  },
  {
    id: "nav-customer-reward",
    name: "Customer Reward",
    url: "/customer-reward",
    icon: "icon-present",
    roles: [99, 98]
  },
  {
    id: "nav-customer-redeem",
    name: "Customer Redeem",
    url: "/customer-redeem",
    icon: "icon-present",
    roles: [99, 98]
  },
  {
    id: "nav-retur",
    name: "Retur",
    url: "/retur",
    icon: "icon-loop",
    roles: [99, 95]
  },
  {
    id: "nav-message",
    name: "Pesan",
    url: "/message",
    icon: "icon-envelope",
    roles: [99, 98]
  },
  {
    name: "Finance",
    icon: "icon-layers",
    roles: [99, 98],
    children: [
      {
        id: "nav-finance-ar",
        name: "Receivable",
        url: "/ar",
        icon: "icon-layers"
      },
      {
        id: "nav-finance-history",
        name: "History",
        url: "/ar",
        icon: "icon-layers"
      },
      {
        id: "nav-finance-bank",
        name: "Bank",
        url: "/bank",
        icon: "icon-layers"
      }
    ]
  },
  {
    id: "nav-user",
    name: "Staff Intertec",
    url: "/user",
    icon: "icon-user",
    roles: [99]
  },
  {
    id: "nav-thread",
    name: "Thread",
    url: "/thread",
    icon: "icon-envelope",
    roles: [98]
  },
  {
    id: "nav-claim",
    name: "Claim",
    url: "/claim",
    icon: "icon-loop",
    roles: [99]
  },
  {
    id: "nav-setting",
    name: "Setting",
    url: "/setting",
    icon: "icon-settings",
    roles: [99],
    children: [
      {
        id: "nav-setting-access",
        name: "Hak Akses",
        url: "/access-right",
        icon: "icon-settings"
      },
      {
        id: "nav-setting-config",
        name: "General Config",
        url: "/setting",
        icon: "icon-settings"
      },
      {
        id: "nav-setting-config",
        name: "Faq",
        url: "/faq",
        icon: "icon-settings"
      },
      {
        id: "nav-setting-config",
        name: "Topic",
        url: "/topic",
        icon: "icon-settings"
      }
    ]
  }
];

export default {
  get: () => {
    const role = JSON.parse(localStorage.getItem("roles"));
    const userRole = role[0].id;
    let filterNav = navs.filter(n => n.roles ? n.roles.includes(userRole) : null);
    filterNav.unshift(navs[0])
    return { items: filterNav };
  }
};
