import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading
});

const Message = Loadable({
  loader: () => import("./app/Message"),
  loading: Loading
});

const User = Loadable({
  loader: () => import("./app/MyUser"),
  loading: Loading
});

const UserForm = Loadable({
  loader: () => import("./app/UserForm"),
  loading: Loading
});

const Stockist = Loadable({
  loader: () => import("./app/Stockist"),
  loading: Loading
});

const Customer = Loadable({
  loader: () => import("./app/Customer"),
  loading: Loading
});

const CustomerView = Loadable({
  loader: () => import("./app/CustomerView"),
  loading: Loading
});

const CustomerForm = Loadable({
  loader: () => import("./app/CustomerForm"),
  loading: Loading
});

const CustomerReward = Loadable({
  loader: () => import("./app/CustomerReward"),
  loading: Loading
});

const CustomerRewardForm = Loadable({
  loader: () => import("./app/CustomerRewardForm"),
  loading: Loading
});

const CustomerRedeem = Loadable({
  loader: () => import("./app/CustomerRedeem"),
  loading: Loading
});

const CustomerRedeemForm = Loadable({
  loader: () => import("./app/CustomerRedeemForm"),
  loading: Loading
});

const Dealer = Loadable({
  loader: () => import("./app/Dealer"),
  loading: Loading
});

const DealerPending = Loadable({
  loader: () => import("./app/DealerPending"),
  loading: Loading
});

const DealerMasterForm = Loadable({
  loader: () => import("./app/DealerMasterForm"),
  loading: Loading
});

const DealerView = Loadable({
  loader: () => import("./app/DealerView"),
  loading: Loading
});

const StockistView = Loadable({
  loader: () => import("./app/StockistView"),
  loading: Loading
});

const StockistPoint = Loadable({
  loader: () => import("./app/StockistPoint"),
  loading: Loading
});

const DealerPoint = Loadable({
  loader: () => import("./app/DealerPoint"),
  loading: Loading
});

const Staff = Loadable({
  loader: () => import("./app/Staff"),
  loading: Loading
});

const StaffForm = Loadable({
  loader: () => import("./app/StaffForm"),
  loading: Loading
});

const StaffView = Loadable({
  loader: () => import("./app/StaffView"),
  loading: Loading
});

const Configuration = Loadable({
  loader: () => import("./app/Configuration"),
  loading: Loading
});

const ConfigurationForm = Loadable({
  loader: () => import("./app/ConfigurationForm"),
  loading: Loading
});

const AccessRight = Loadable({
  loader: () => import("./app/AccessRight"),
  loading: Loading
});

const AccessRightForm = Loadable({
  loader: () => import("./app/AccessRightForm"),
  loading: Loading
});

const IMEI = Loadable({
  loader: () => import("./app/Imei"),
  loading: Loading
});

const Product = Loadable({
  loader: () => import("./app/Product"),
  loading: Loading
});

const ProductForm = Loadable({
  loader: () => import("./app/ProductForm"),
  loading: Loading
});

const ProductView = Loadable({
  loader: () => import("./app/ProductView"),
  loading: Loading
});

const ProductStockist = Loadable({
  loader: () => import("./app/ProductStockist"),
  loading: Loading
});

const PointRule = Loadable({
  loader: () => import("./app/PointRule"),
  loading: Loading
});

const PointRuleForm = Loadable({
  loader: () => import("./app/PointRuleForm"),
  loading: Loading
});

const PointRuleView = Loadable({
  loader: () => import("./app/PointRuleView"),
  loading: Loading
});

const PointBalance = Loadable({
  loader: () => import("./app/PointBalance"),
  loading: Loading
});

const InjectPoint = Loadable({
  loader: () => import("./app/InjectPoint"),
  loading: Loading
});

const Profile = Loadable({
  loader: () => import("./app/Profile"),
  loading: Loading
});
const Brand = Loadable({
  loader: () => import("./app/Brand"),
  loading: Loading
});
const IMEIHistory = Loadable({
  loader: () => import("./app/SerialNumber"),
  loading: Loading
});
const ProductCategory = Loadable({
  loader: () => import("./app/ProductCategory"),
  loading: Loading
});

const Bank = Loadable({
  loader: () => import("./app/Bank"),
  loading: Loading
});

const BankForm = Loadable({
  loader: () => import("./app/BankForm"),
  loading: Loading
});

const BankView = Loadable({
  loader: () => import("./app/BankView"),
  loading: Loading
});

const StockistForm = Loadable({
  loader: () => import("./app/StockistForm"),
  loading: Loading
});

const SalesOrderForm = Loadable({
  loader: () => import("./app/SalesOrderForm"),
  loading: Loading
});

const SalesOrderView = Loadable({
  loader: () => import("./app/SalesOrderView"),
  loading: Loading
});

const SalesOrderEdit = Loadable({
  loader: () => import("./app/SalesOrderEdit"),
  loading: Loading
});

const SalesOrder1 = Loadable({
  loader: () => import("./app/SalesOrder1"),
  loading: Loading
});

const SalesOrder2 = Loadable({
  loader: () => import("./app/SalesOrder2"),
  loading: Loading
});
const SalesOrder3 = Loadable({
  loader: () => import("./app/SalesOrder3"),
  loading: Loading
});
const SalesOrder4 = Loadable({
  loader: () => import("./app/SalesOrder4"),
  loading: Loading
});
const ReportStock = Loadable({
  loader: () => import("./app/report/ReportStock"),
  loading: Loading
});
const ReportStockDeep = Loadable({
  loader: () => import("./app/report/ReportStockDeep"),
  loading: Loading
});
const Retur = Loadable({
  loader: () => import("./app/Retur"),
  loading: Loading
});
const ReturForm = Loadable({
  loader: () => import("./app/ReturForm"),
  loading: Loading
});
const ReturView = Loadable({
  loader: () => import("./app/ReturView"),
  loading: Loading
});
const Reward = Loadable({
  loader: () => import("./app/Reward"),
  loading: Loading
});
const RewardForm = Loadable({
  loader: () => import("./app/RewardForm"),
  loading: Loading
});
const RewardView = Loadable({
  loader: () => import("./app/RewardView"),
  loading: Loading
});
const Faq = Loadable({
  loader: () => import("./app/faq/Faq"),
  loading: Loading
});
const FaqForm = Loadable({
  loader: () => import("./app/faq/FaqForm"),
  loading: Loading
});
const FaqView = Loadable({
  loader: () => import("./app/faq/FaqView"),
  loading: Loading
});

const Topic = Loadable({
  loader: () => import("./app/topic/Topic"),
  loading: Loading
});
const TopicForm = Loadable({
  loader: () => import("./app/topic/TopicForm"),
  loading: Loading
});
const TopicView = Loadable({
  loader: () => import("./app/topic/TopicView"),
  loading: Loading
})
const Redeem = Loadable({
  loader: () => import("./app/Redeem"),
  loading: Loading
});
const RedeemPending = Loadable({
  loader: () => import("./app/RedeemPending"),
  loading: Loading
});

const Thread = Loadable({
  loader: () => import("./app/thread/Thread"),
  loading: Loading
});
const ThreadView = Loadable({
  loader: () => import("./app/thread/ThreadView"),
  loading: Loading
});
const Claim = Loadable({
  loader: () => import("./app/claim/Claim"),
  loading: Loading
});
const ClaimView = Loadable({
  loader: () => import("./app/claim/ClaimView"),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Home", component: DefaultLayout },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/message", exact: true, name: "Pesan", component: Message },
  { path: "/user", exact: true, name: "Staff Intertec", component: User },
  { path: "/user/form", name: "Form", component: UserForm },
  {
    path: "/stockist",
    exact: true,
    name: "Stockist",
    component: Stockist
  },
  {
    path: "/customer",
    exact: true,
    name: "Customer",
    component: Customer
  },
  {
    path: "/customer/view",
    exact: true,
    name: "Detail",
    component: CustomerView
  },
  {
    path: "/customer/form",
    exact: true,
    name: "Form",
    component: CustomerForm
  },
  {
    path: "/customer-reward",
    exact: true,
    name: "Customer Reward",
    component: CustomerReward
  },
  {
    path: "/customer-reward/form",
    exact: true,
    name: "Form",
    component: CustomerRewardForm
  },
  {
    path: "/customer-redeem",
    exact: true,
    name: "Customer Redeem",
    component: CustomerRedeem
  },
  {
    path: "/customer-redeem/form",
    exact: true,
    name: "Form",
    component: CustomerRedeemForm
  },
  {
    path: "/dealer",
    exact: true,
    name: "Dealer",
    component: Dealer
  },
  {
    path: "/dealer-pending",
    exact: true,
    name: "Dealer Pending",
    component: DealerPending
  },
  {
    path: "/stockist-view",
    name: "Detail Stockist",
    component: StockistView
  },
  {
    path: "/dealer-view",
    name: "Dealer View",
    component: DealerView
  },
  {
    path: "/dealer-master/form/:type",
    name: "Form",
    component: DealerMasterForm
  },
  {
    path: "/stockist-point",
    exact: true,
    name: "Stockist Point",
    component: StockistPoint
  },
  {
    path: "/dealer-point",
    exact: true,
    name: "Dealer Point",
    component: DealerPoint
  },
  {
    path: "/dealer",
    exact: true,
    name: "Dealer",
    component: Dealer
  },
  { path: "/staff/:id", exact: true, name: "Karyawan", component: Staff },
  {
    path: "/staff-form/:id",
    name: "Form Staff",
    component: StaffForm
  },
  {
    path: "/staff-view",
    name: "Detail Staff",
    component: StaffView
  },
  { path: "/imei", exact: true, name: "IMEI", component: IMEI },
  {
    path: "/imei-history",
    exact: true,
    name: "IMEI History",
    component: IMEIHistory
  },
  { path: "/product", exact: true, name: "Product", component: Product },
  { path: "/product/form", name: "Form", component: ProductForm },
  { path: "/product/view", name: "Detail", component: ProductView },
  {
    path: "/product/stockist",
    name: "Mapping Stockist",
    component: ProductStockist
  },
  {
    path: "/point-rule",
    exact: true,
    name: "Aturan Poin",
    component: PointRule
  },
  { path: "/point-rule/form", name: "Form", component: PointRuleForm },
  { path: "/point-rule/view", name: "View", component: PointRuleView },
  {
    path: "/point-balance",
    exact: true,
    name: "Point History",
    component: PointBalance
  },
  {
    path: "/point-inject",
    exact: true,
    name: "Inject Point",
    component: InjectPoint
  },
  { path: "/profile", exact: true, name: "User Profile", component: Profile },
  { path: "/brand", exact: true, name: "Brand", component: Brand },
  {
    path: "/product-category",
    exact: true,
    name: "Product Category",
    component: ProductCategory
  },
  { path: "/bank", exact: true, name: "Bank", component: Bank },
  { path: "/bank/form", name: "Form", component: BankForm },
  { path: "/bank/view", name: "Detail", component: BankView },
  { path: "/stockist", exact: true, name: "Mitra", component: Stockist },
  { path: "/stockist/form", name: "Form", component: StockistForm },
  { path: "/sales-order/form", name: "Form", component: SalesOrderForm },
  { path: "/sales-order/view", name: "Detail", component: SalesOrderView },
  { path: "/sales-order/edit", name: "Edit", component: SalesOrderEdit },
  {
    path: "/so-intertec-stockist",
    exact: true,
    name: "SO Intertec - Stockist",
    component: SalesOrder1
  },
  {
    path: "/so-stockist-dealer",
    exact: true,
    name: "SO Stockist - Dealer",
    component: SalesOrder2
  },
  {
    path: "/so-stockist-customer",
    exact: true,
    name: "SO Stockist - Customer",
    component: SalesOrder4
  },
  {
    path: "/so-dealer-customer",
    exact: true,
    name: "SO Dealer - Customer",
    component: SalesOrder3
  },
  {
    path: "/setting",
    exact: true,
    name: "Setting",
    component: Configuration
  },
  {
    path: "/setting/form",
    name: "Form",
    component: ConfigurationForm
  },
  {
    path: "/access-right",
    exact: true,
    name: "Hak Akses",
    component: AccessRight
  },
  {
    path: "/access-right/form",
    name: "Form",
    component: AccessRightForm
  },
  {
    path: "/report-stock",
    exact: true,
    name: "Report Stock",
    component: ReportStock
  },
  {
    path: "/report-stock-deep",
    exact: true,
    name: "Report Stock Breakdown",
    component: ReportStockDeep
  },
  {
    path: "/retur",
    exact: true,
    name: "Retur",
    component: Retur
  },
  {
    path: "/retur/form",
    name: "Form",
    component: ReturForm
  },
  {
    path: "/retur/view",
    name: "Detail",
    component: ReturView
  },
  {
    path: "/reward",
    exact: true,
    name: "Reward",
    component: Reward
  },
  {
    path: "/reward/form",
    name: "Form",
    component: RewardForm
  },
  {
    path: "/reward/view",
    name: "Detail",
    component: RewardView
  },
  {
    path: "/faq",
    name: "Faq",
    exact: true,
    component: Faq
  },
  {
    path: "/faq/form",
    name: "Create",
    exact: true,
    component: FaqForm
  },
  {
    path: "/faq/view",
    name: "Detail",
    exact: true,
    component: FaqView
  },
  {
    path: "/Topic",
    name: "Topic",
    exact: true,
    component: Topic
  },
  {
    path: "/topic/form",
    name: "Create",
    exact: true,
    component: TopicForm
  },
  {
    path: "/topic/view",
    name: "Detail",
    exact: true,
    component: TopicView
  },
  {
    path: "/redeem",
    name: "Redeem",
    component: Redeem
  },
  {
    path: "/redeem-pending",
    name: "Redeem Pending",
    component: RedeemPending
  },
  {
    path: "/thread",
    name: "Thread",
    component: Thread,
    exact: true
  },
  {
    path: "/thread/view",
    name: "Detail",
    component: ThreadView,
    exact: true
  },
  {
    path: "/claim",
    name: "Claim",
    component: Claim,
    exact: true
  },
  {
    path: "/claim/view",
    name: "Claim Detail",
    component: ClaimView,
    exact: true
  },
];

export default routes;
