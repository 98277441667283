import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form
} from "reactstrap";

class ForgotPassword extends Component {
  state = { email: "" };

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  submit(e) {
    e.preventDefault();
    axios
      .post("/auth/forgot-password", { username: this.state.email })
      .then(r =>
        toast.success("Silahkan cek email Anda untuk recovery password Anda")
      );
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Forgot Password</h1>
                    <p className="text-muted">Silahkan masukan Email Anda</p>
                    <Form onSubmit={e => this.submit(e)}>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={e => this.changeEmail(e)}
                          value={this.state.email}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            className="px-4"
                            onClick={this.loginClick}
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button
                            color="link"
                            className="px-0"
                            onClick={() => this.props.history.push("/login")}
                          >
                            Login?
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
