import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";
import "./scss/_custom.scss";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import { Page404, Page500, Register } from "./views/Pages";

import Login from "./app/Login";
import ForgotPassword from "./app/ForgotPassword";

// import { renderRoutes } from 'react-router-config';

import { loadProgressBar } from "axios-progress-bar";
import axios from "axios";
// https://github.com/fkhadra/react-toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "axios-progress-bar/dist/nprogress.css";
import Axios from "axios";

class App extends Component {
  componentDidMount() {
    // initial data
    Axios.get("/auth/roles").then(response =>
      localStorage.setItem("rolesBank", JSON.stringify(response.data))
    );
    Axios.get("/web-access-right/allowed-ids").then(response =>
      localStorage.setItem("allowed-ids", JSON.stringify(response.data))
    );
  }
  render() {
    loadProgressBar();
    return (
      <div>
        <ToastContainer />
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

axios.interceptors.request.use(
  config => {
    const url = "http://intertec-dev.ap-southeast-1.elasticbeanstalk.com";
    // const url = "http://localhost:4000";
    // document.body.classList.add("loading-indicator");
    if (config.url.indexOf("http") < 0) {
      config.url = url + config.url;
      config.headers["token"] = localStorage.getItem("token");
    }
    // if (config.data instanceof FormData) {
    //   const temp = {};
    //   config.data.forEach(function(value, key) {
    //     temp[key] = value;
    //   });
    //   config.data = temp;
    // }
    // Do something before request is sent
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // document.body.classList.remove("loading-indicator");
    if (response.data.token) localStorage.setItem("token", response.data.token);
    return response;
  },
  error => {
    if (error && error.response && error.response.data)
      toast.error(error.response.data.message);
    // ==== tidak perlu logout user klo forbidden error
    // if (error && error.response && error.response.status === 403) {
    //   window.location.replace("/#/login");
    // } else {
    //   if (error && error.response && error.response.data)
    //     toast.error(error.response.data.message);
    // }
    return Promise.reject(error);
  }
);

export default App;
