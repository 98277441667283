import { toast } from "react-toastify";
import moment from "moment";

const util = {
  url: "http://intertec-dev.ap-southeast-1.elasticbeanstalk.com",
  // url: "http://localhost:4000",
  toDate: (o, format) => {
    if (format === undefined) format = "DD MMMM YYYY";
    if (new Date(o) !== "Invalid Date" && !isNaN(new Date(o)))
      return moment(new Date(o), "Asia/Jakarta").format(format);
    else return o;
  },
  toString: o => {
    if (new Date(o) !== "Invalid Date" && !isNaN(new Date(o))) {
      const date = new Date(o);
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      return y + "-" + m + "-" + d;
    }
  },
  format: o => {
    if (o == null) return o;
    else if (!isNaN(o))
      return o.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else if (new Date(o) !== "Invalid Date" && !isNaN(new Date(o)))
      return moment(new Date(o)).format("DD MMM YYYY");
    else return o;
  },
  httpOptions: (method, data) => {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      },
      body: data ? JSON.stringify(data) : undefined
    };
    return options;
  },
  errorHandling: (error, history, afterLogged) => {
    if (error.status == 403) {
      localStorage.setItem("afterLogged", afterLogged);
      if (history) history.push("/login");
      else window.location.href = "/login";
    }
    try {
      error.text().then(message => toast.error(JSON.parse(message).message));
    } catch (x) {
      toast.error(error.message);
    }
  },
  handleResponse: response => {
    const token = response.headers.get("token");
    localStorage.setItem("token", token);
  },
  handleChange: (e, initial, callback) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;
    if (target.type === "checkbox") {
      if (initial instanceof Array) {
        const v = e.target.value;
        initial = initial ? initial : [];
        if (initial.indexOf(v) > -1) {
          value = initial.filter(s => s !== v);
        } else {
          value = [...initial, v];
        }
      } else {
        if (initial || !initial) {
          value = !initial;
        }
      }
    } else if (target.type === "file") {
      value = e.target.files[0];
    }
    callback({ [name]: value });
  },
  isRole: name => {
    const roles = JSON.parse(localStorage.getItem("roles"));
    for (const i in roles) {
      const r = roles[i];
      if (r.name.toLowerCase() === name.toLowerCase()) {
        return true;
      }
    }
    return false;
  },
  promptQuantity: (message, defaultValue) => {
    let n = window.prompt(message, defaultValue);
    if (n == null) return;
    if (isNaN(n)) {
      toast.error("Jumlah tidak valid");
      return;
    }
    if (n <= 0) {
      toast.error("Jumlah tidak valid");
      return;
    }
    return n;
  },
  parse: s => (s == null ? "" : s),

  copyToClipboard: str => {
    const el = document.createElement("textarea"); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
    el.style.position = "absolute";
    el.style.left = "-9999px"; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0 // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0) // Store selection if found
        : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
      // If a selection existed before copying
      document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
      document.getSelection().addRange(selected); // Restore the original selection
    }
    toast.success("Copied!");
  },
  titleCase: str => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
};

export default util;
